<template>
  <div class="form-container">
    <h2 class="mb-3" v-if="this.form.issue_id">
      Reporting Information for "{{ form.title }}"
    </h2>
    <h3 class="mb-3 font-weight-bold" v-else>Report A Bug</h3>

    <b-alert show variant="success" v-if="this.$route.params.message">
      {{ this.$route.params.message }}
    </b-alert>

    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        v-if="!this.form.issue_id"
        label="Title"
        label-for="input-1"
        label-cols-md="2"
        description='A short headline for the issue you are having. For example: "Crash at Dome when looting".'
      >
        <b-form-input
          id="input-1"
          v-model="form.title"
          required
          placeholder="Briefly describe the issue"
          maxlength="180"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Platform"
        label-for="input-2"
        label-cols-md="2"
      >
        <b-form-select
          id="input-2"
          v-model="form.platform"
          :options="platforms"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Issue Details"
        label-for="input-3"
        label-cols-md="2"
        description="Please provide as much detail as you can to help us understand the issue."
      >
        <b-form-textarea
          id="input-3"
          v-model="form.description"
          placeholder="Describe the issue in as much detail as you can"
          required
          rows="3"
          max-rows="6"
          maxlength="1000"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Steps to Reproduce"
        label-for="input-4"
        label-cols-md="2"
      >
        <b-form-textarea
          id="input-4"
          v-model="form.repro_steps"
          placeholder="How do you reproduce the issue?"
          rows="3"
          max-rows="6"
          maxlength="1000"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        label="Xbox Gamertag/PSN ID"
        label-for="input-5"
        label-cols-md="2"
        description="This may help us find data relating to your issue."
      >
        <b-form-input
          id="input-5"
          v-model="form.platform_username"
          maxlength="16"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-6"
        label="Bug Type"
        label-for="input-6"
        label-cols-md="2"
        description="Pick the tags that best describe the category of your issue."
      >
        <b-form-select
          id="input-6"
          v-model="form.type"
          :options="types"
        ></b-form-select>         
      </b-form-group>

      <b-form-group
        id="input-group-7"
        label="Monument"
        label-for="input-7"
        label-cols-md="2"
        description="If the issue is related to a monument, select which here."
      >
        <b-form-select
          id="input-7"
          v-model="form.monument"
          :options="monuments"
        ></b-form-select>
      </b-form-group>           

      <div class="clearfix">
        <b-button
          v-if="this.form.issue_id"
          variant="light"
          class="float-left"
          :to="{ name: 'Bugs' }"
          >Skip</b-button
        >
        <b-button type="submit" variant="primary" class="mx-1 float-right"
          >Submit</b-button
        >
        <b-button type="reset" variant="danger" class="mx-1 float-right"
          >Reset</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {
        title: "",
        platform: null,
        description: "",
        repro_steps: "",
        platform_username: "",
        type: null,
        monument: null,
        issue_id: null,
      },
      platforms: [
        { text: "Select One", value: null },
        "Xbox One Base",
        "Xbox One S",
        "Xbox One X",
        "PS4 Base",
        "PS4 Pro",
      ],
      types: [
        { text: "Select One", value: null },
        "Audio",        
        "Graphical", 
        "Gameplay", 
        "Performance", 
        "Networking", 
        "Crash", 
        "Balance", 
        "User Interface", 
        "Quality of Life",      
      ],
      monuments: [
        { text: "Select One", value: null },
        "Oxum's gas station",
        "Airfield",
        "Bandit camp",
        "Stone quarry",
        "Sulfur quarry",
        "HQM quarry",
        "Launch site",   
        "Mining outpost",
        "Harbour",
        "Dome",
        "Satellite dish",
        "Sewer branch",
        "Power plant"
      ],      
      show: true,
    };
  },
  methods: {
    onSubmit(evt) {        
      axios({
        method: "post",
        url: `${process.env.VUE_APP_API_ROOT}/report/new`,
        data: this.form,
      });

      const message =
        "Thanks for your report! " +
        (this.form.issue_id
          ? ""
          : "It will appear here once it's been reviewed.");
      this.$router.push({ name: "Bugs", params: { message } });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      (this.form.title = ""),
        (this.form.platform = null),
        (this.form.description = ""),
        (this.form.repro_steps = ""),
        (this.form.platform_username = ""),
        (this.form.type = null),
        (this.form.monument = null);
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    } 
  },
  created() {
    this.form.issue_id = this.$route.params.issue_id;
    this.form.title = this.$route.params.title;
  },
};
</script>

<style lang="scss">
@import "../assets/css/variables.scss";

.form-container {
  padding: 3rem 0;
  max-width: 900px;
}

.text-muted {
  color: $light-grey !important;
}

.multiselect__placeholder {
  color: #495057;
  font-size: 1rem;
}

.multiselect__tags {
    height: 37px;
    padding: 3px 40px 0 9px;  
}

.multiselect__tag {
  margin-top: 3px;
  background: $action;
}

.multiselect__tag-icon:after {
  color: #ffffff;
}

.multiselect__input, .multiselect__single {
  min-height: 30px;
  padding: 0;
}
</style>
